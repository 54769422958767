<template>
  <div class="content-box">
    <div class="content">
      <div class="search-action">
        <div class="inputs_filter">
          <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            class="search-input"
            v-model="search"
            clearable
            @clear="getList"
          >
          </el-input>
          <div class="search-btn" @click="getList()">搜索</div>
        </div>
      </div>

      <div class="recommend">
        <div class="recommend-title">
          <span class="big-title">为你推荐</span>
        </div>
        <div class="recommend-outer">
          <!---->
          <ul class="recommend-content">
            <li
              class="recommend-item"
              v-for="(item, index) in recommendList"
              :key="index"
              @click="onNeed(item.id)"
            >
              <div class="expert-link">
                <div class="recommend-item-top">
                  <div class="recommend-item-img">
                    <img v-if="item.img" :src="urlImg + item.img" alt="" />
                    <img
                      v-else
                      src="../../../assets/img/huiyuanzhongxin/default_error.png"
                      alt=""
                    />
                  </div>
                  <h2 class="recommend-item-info">
                    {{ item.title }}
                  </h2>
                  <div class="cur_status">
                    <span class="service" v-if="item.service == 1">待服务</span>
                    <span class="service" v-else-if="item.service == 2"
                      >服务中</span
                    >
                    <span class="service" v-else>已结束（成功）</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <pagination
            class="pages"
            v-show="total > 0"
            :total="total"
            layout="prev, pager, next"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.limit"
            @pagination="getList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { demandLists } from "@/common/js/api";
import Pagination from "@/common/Pagination";
import { urlImg } from "@/common/js/url";
export default {
  components: { Pagination },
  data() {
    return {
      recommendList: [],
      total: 0, //总条目数
      listQuery: {
        limit: 6,
        page: 1,
      },
      search: "",
      urlImg: "",
    };
  },
  created() {
    this.getList();
    this.urlImg = urlImg();
  },
  methods: {
    //  列表
    getList() {
      demandLists({
        keywords: this.search,
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      })
        .then((res) => {
          this.recommendList = res.data.data;
          this.total = res.data.total;
        })
        .catch(() => {});
    },

    //   前往需求详情
    onNeed(id) {
      let routeData = this.$router.resolve({
        path: "/need_details",
        query: {
          id: id,
        },
      });
       window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .search-action {
    margin: 20px 30% 16px 20%;
  }
  /deep/.el-input__inner {
    border: 0;
  }
  .recommend {
    .recommend-outer {
      .recommend-content {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        .recommend-item:nth-child(3n + 0) {
          margin-right: 0;
        }
         .recommend-item:hover {
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
             transform: scale(1.05);
          }
        .recommend-item {
          border-radius: 5px;
          margin-top: 23px;
          width: calc(33.3% - 15px);
          height: 300px;
          margin-right: 20px;
          background: #fff;
          margin-bottom: 10px;
          cursor: pointer;

          .expert-link {
            color: #00050a;
            font-size: 16px;
            .recommend-item-img {
              width: 100%;
              height: 200px;
              border: 1px solid #bbb;
            }
            .recommend-item-info {
              padding: 8px;
              line-height: 1.5;
               overflow: hidden;
             text-overflow: ellipsis;
             white-space: nowrap;
             height: 24px;
            }
            .cur_status {
              width: 100%;
              box-sizing: border-box;
              color: #3892eb;
              text-align: right;
              padding-right: 10px;
              .service {
                width: 92px;
                height: 24px;
                line-height: 24px;
                border: 1px solid #3892eb;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
    .pagination {
      text-align: center;
    }
  }
}
</style>